import { Component, OnInit } from '@angular/core';

@Component({
    templateUrl: './patient-view.component.html',
    styleUrls: ['./patient-view.component.scss']
})

export class PatientView implements OnInit {
    
    constructor() {

    }
    
    ngOnInit() {

    }
}