import { Component, OnInit } from '@angular/core';

@Component({
    templateUrl: './rating-view.component.html',
    styleUrls: ['./rating-view.component.scss']
})

export class RatingView implements OnInit {
    
    constructor() {

    }
    
    ngOnInit() {

    }
}