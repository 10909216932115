
import {map, mergeMap, catchError} from 'rxjs/operators';

import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../services/auth.service';

import { LoadingService } from '../services/loading.service';
import { NotificationService } from '../services/notification.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private injector: Injector, 
                private loadingService: LoadingService, 
                private notificationService: NotificationService,
                private router: Router) {

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let auth = this.injector.get(AuthService);

        request = request.clone({
            setHeaders: {
                Authorization: 'Bearer ' + auth.getToken()
            }
        });

        return next.handle(request).pipe(map((event: HttpEvent<any>) => {

            if (event instanceof HttpResponse) {
                //console.log('Intercepted event: ', event);
                return event;
            }

        }),catchError((error: any) => {

            this.loadingService.hide();
            
            if (error instanceof HttpErrorResponse) {

                if (error.status === 0) {
                    //console.log('Error connecting to api');
                    this.notificationService.error('Error connecting to API');
                    return observableThrowError(error);
                }

                if (error.status === 420) {
                    this.router.navigate(['login']);
                    return observableThrowError(error);
                }

                if (error.status === 401) {
                    
                    return auth.refreshToken().pipe(mergeMap(res => {

                        auth.setToken(res.authToken, res.user);
                        
                        let authReq = request.clone({
                            setHeaders: {
                                Authorization: 'Bearer ' + res.authToken
                            }
                        });

                        return next.handle(authReq);
                    }))
                }
                return observableThrowError(error);
            }
            else {
                return observableThrowError(error);
            }
        }),);

    }
}