import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import { User } from '../../models/user.interface';
import { Observable } from 'rxjs';

@Component({
    selector: 'navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss']
})

export class Navigation implements OnInit {

    ngOnInit(): void {
        console.log('Navigation loaded');
        this.isAuthenticated = this.authService.isAuthenticated();
        if (this.isAuthenticated) this.getUser();
    }

    isAuthenticated: Observable<boolean>|boolean;
    user: User;

    constructor(private authService: AuthService, private router: Router, private userService: UserService) {
        this.authService.loggedIn.subscribe(res => {
            if (!res) {
                this.isAuthenticated = false;
                return;
            }
            this.isAuthenticated = true;
            this.getUser();
        });
    }
    
    logout(): void {
        this.authService.logout();
        this.router.navigate(['login']);
        this.isAuthenticated = false;
    }
    
    getUser(): void {
        
        this.user = this.authService.getUser();
        if (this.user) {
            this.isAuthenticated = true;
            return;
        }
        this.isAuthenticated = false;
        
    }
}