import { Component, OnInit } from '@angular/core';
import { fadeInOutAnimation } from '../../animations/fadeInOut.animation';

@Component({
    animations: [ fadeInOutAnimation ],
    templateUrl: './patient-home-view.component.html',
    styleUrls: ['./patient-home-view.component.scss']
})

export class PatientHomeView implements OnInit {
    
    constructor() {

    }
    
    ngOnInit() {

    }
}