import { Component, OnInit } from '@angular/core';
import { Notification, NotificationType } from '../../models/notification.model';
import { NotificationService } from '../../services/notification.service';
import { slideInOutVerticalAnimation } from '../../animations/slideInOutVertical.animation';

@Component({
    animations: [ slideInOutVerticalAnimation ],
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
    selector: 'notification'
})

export class NotificationComponent {

    notifications: Notification[] = [];

    constructor(private notificationService: NotificationService) {}

    ngOnInit() {
        this.notificationService.getNotification().subscribe((notification: Notification) => {
            if (!notification) {
                this.notifications = [];
                return;
            }
            this.notifications.push(notification);
        });
    }

    removeNotification(notification: Notification): void {
        this.notifications = this.notifications.filter(x => x != notification);
    }

    css(notification: Notification) {
        if (!notification) {
            return;
        }

        switch(notification.type) {
            case NotificationType.Success:
                return 'notification success';
            case NotificationType.Error:
                return 'notification error';
            case NotificationType.Info:
                return 'notification info';
            case NotificationType.Warning:
                return 'notification warning';
        }
    }
}