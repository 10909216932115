import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../services/http.service';


@Component({
    templateUrl: './mock-view.component.html',
    styleUrls: ['./mock-view.component.scss']
})

export class MockView implements OnInit {

    constructor(private httpService: HttpService) {

      this.options = {
          title : { text : 'Dialysis Results' },
          plotOptions: {
            series: {
              turboThreshold: 0
            }
          }
      };

    }
    
    ngOnInit() {
        
    }

    private critStart: number = 11000;
    private critStability: number = 3;
    private critInterval: number = 20;
    private critIncrement: number = 50;

    private signSmart: number[];

    options: any;
    chart: any;
    useSmart: boolean;

    saveInstance(chart) {
      this.chart = chart;
    }

    handleFile(input) {
      const file = input.target.files[0];
      const reader = new FileReader();

      reader.onload = (event) => {
        const file = reader.result;
        const allLines = file.split(/\r\n|\n/);

        let data: number[] = [];

        if (this.useSmart) {

          allLines.map((line) => {
            data.push(line);
          });

          this.smart(data, this.critInterval);
          
          this.chart.addSeries({
            id: 'smart',
            name: 'smart',
            data: this.signSmart
          });

        }
        else {
          allLines.map((line) => {
            data.push(parseInt(line));
          });
          
          this.chart.addSeries({
            id: 'raw',
            name: 'raw',
            data: data
          });
        }

      };

      reader.onerror = (event) => {
        console.log(reader.error.name);
      };

      reader.readAsText(file);
    }

    handleType(event) {
      
      if (event.target.checked) {

        this.httpService.getData('test', event.target.name).subscribe(data => {

          this.chart.addSeries({
            id: event.target.name,
            name: event.target.name,
            data: data['values']
          });

        }, err => {

          console.log(err);

        });

        return;
      }
      this.chart.get(event.target.name).remove();

    }

    onResize(event) {
      let width = event.target.innerWidth;
      this.chart.setSize(width-100, width * (3/4), false);
    }

    smart(data: number[], interval: number) {

      this.signSmart = new Array<number>();
      
      let freq = new Map<number, number>();
      let sum = new Map<number, number>();

      this.signSmart[0] = (data[0] | 0);

      console.log(this.signSmart[0]);

      let start = 1;
      let count = 0;

      while ( !( (data[start] - data[start-1]) > 0 && data[start] < this.critStart && count > this.critStability) ) {

          if ( (data[start] - data[start-1]) > -this.critIncrement && (data[start] - data[start-1]) < this.critIncrement  && data[start] < this.critStart ) {
            count++;
          }
          else {
            count = 0;
          }

          this.signSmart[start] = (data[start] | 0);
          start++;

          if (start == data.length) {
            console.log('Cant find START point');
            return;
          }

      }

      console.log('START: ' + start);

      let key: number;
      let maxkey: number = 0;
      let step: number = interval;
      let ctrlSum: number = 0;
      let curSum: number = 0;
      let diff: number;
      let counter: number = 0;
      let update: boolean = false;
      let ctrlPoint: number = start;

      for (let i = start; i < data.length; i++) {

        diff = (data[i] - data[i-1] | 0);
        key = (diff/step | 0);

        if (!freq.has(key)) {
          freq.set(key, 0);
          sum.set(key, 0);
        }

        freq.set(key, freq.get(key)+1);
        sum.set(key, sum.get(key)+diff);

        curSum += diff;

        if ( diff > -this.critIncrement && diff < this.critIncrement) {

          counter++;
          if (counter >= this.critStability) {

            if ( ((curSum-ctrlSum)/(i-ctrlPoint+1) | 0) < this.critIncrement && ((curSum-ctrlSum)/(i-ctrlPoint+1) | 0) >= 0 ) {
              ctrlSum = curSum;
              update = true;
            }
            else {
              counter = 0;
            }
          }
        }
        else {
          counter = 0;
          update = false;
        }

        maxkey = this.getMostFrequent(freq, key);

        if (update) {
          ctrlPoint = i;
          
          if ( (data[start-1]+ctrlSum) > (this.signSmart[i-1]+ctrlSum/(ctrlPoint-start+1)) ) {
            this.signSmart[i] = (data[start-1] | 0) + ctrlSum;
          }
          else {
            this.signSmart[i] = this.signSmart[i-1] + 1;
          }
          update = false;
        }
        else {
          if ( ctrlPoint != start && ((ctrlSum/(ctrlPoint-start+1) | 0) < (sum.get(maxkey)/freq.get(maxkey) | 0)) ) {
            this.signSmart[i] = this.signSmart[i-1] + (ctrlSum / (ctrlPoint - start + 1) | 0);
          }
          else {
            this.signSmart[i] = this.signSmart[i-1] + (sum.get(maxkey) / freq.get(maxkey) | 0);
          }
        }
        
      }

    }

    getMostFrequent(freq, curmaxkey: number) {

      let max: number = freq.get(curmaxkey);
      let key: number = curmaxkey;

      var sorted= Array.from(freq).sort(function(a,b) {
        return a[0] - b[0];
      });

      for (let i of sorted) {
        if (i[1] > max) {
          key = i[0];
          max = i[1];
        }
      }

      return key;
    }

}