import { Component, OnInit } from '@angular/core';
import { SensorData } from '../../models/sensor-data.model';
import { HttpService } from '../../services/http.service';

@Component({
    templateUrl: './session-view.component.html',
    styleUrls: ['./session-view.component.scss'],
    providers: [HttpService]
})

export class SessionView implements OnInit {


    constructor(private httpService: HttpService) {
    }
    
    ngOnInit() {
        
    }

    private id: string;
    private token;

    // startSession(id) {
    //   this.httpService.startSession(id).subscribe(res => {
    //     if (!res.id) {
    //       console.log('Session ID missing.');
    //       return;
    //     }
    //     this.id = res.id;
    //     this.token = setInterval(() => { this.sendRandomData(); }, 1000);

    //     setTimeout(function() {
    //       clearInterval(this.token);
    //     }, 30000);
    //   },
    //   err => {
    //     console.log('Error:');
    //     console.log(err);
    //   });
    // }

    // sendRandomData() {

    //   var numbers = '';

    //   for (let i = 0; i < 12; i++) {
    //     if (numbers.length == 0) {
    //       numbers += Math.floor(Math.random() * 5) + 1;
    //     }
    //     else {
    //       numbers += ',' + Math.floor(Math.random() * 5) + 1;
    //     }
    //   }

    //   console.log(numbers);

    //   let data = new SensorData(this.id, new Date(),1,2,12,12,1,14,2,3,12,4,1,4);
    //   this.httpService.postData(data).subscribe(
    //     res => {
    //       console.log(res);
    //     },
    //     err => {
    //       console.log(err);
    //     }
    //   );
    // }

}