
import {first, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';


@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private authService: AuthService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>|Promise<boolean>|boolean {
        
        if (this.authService.isAuthenticated()) {
            return true;
        }
        if (this.authService.getRefreshToken()) {
            return this.authService.refreshToken().pipe(map(res => {
                if (res.authToken) {
                    this.authService.setToken(res.authToken, res.user);
                    return true;
                }
                this.router.navigate(['login']);
                return false;
            }),first(),);
        }
        else {
            this.router.navigate(['login']);
            return false;
        }

        
    }
    
}
