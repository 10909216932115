import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';

@Component({
    templateUrl: './nurse-view.component.html',
    styleUrls: ['./nurse-view.component.css']
})

export class NurseView implements OnInit {

    @ViewChild(BaseChartDirective) testChart: BaseChartDirective;

    constructor() {

    }

    public interval = setInterval(() => {
        var now = new Date();
        var random = this.testChart.datasets[0].data[this.testChart.datasets[0].data.length-1] + (Math.random()*0.3);
        var random2 = random-0.1;
        console.log(random + " / " + random2);
        this.testChart.datasets[0].data.push(random);
        this.testChart.datasets[1].data.push(random2);
        this.lineChartLabels.push(now.getHours() + ":" + now.getMinutes());
        
        if (this.testChart.datasets[0].data.length > 7) {
            // this.testChart.datasets[0].data.splice(0, 1);
            // this.testChart.datasets[1].data.splice(0, 1);
            // this.testChart.labels.splice(0,1);
            clearInterval(this.interval);
        }
        this.testChart.chart.update();
    }, 1000);

    ngOnInit() {
        console.log(this.testChart);
    }

    ngOnDestroy() {

        clearInterval(this.interval);

    }
    
    public lineChartData:Array<any> = [
        {
            data: [0], 
            label: 'spKtV', 
            fill: false, 
            borderWidth: 3, 
            pointBorderWidth: 1
        },
        {
          data: [0], 
          label: 'eKtV', 
          fill: false, 
          borderWidth: 3, 
          pointBorderWidth: 1
      }
      ];

      public ktvData:Array<any> = [
        {
            data: [0, 0.2, 0.6, 0.8, 1, 1.2, 1.4], 
            label: 'spKtV', 
            fill: false, 
            borderWidth: 3, 
            pointBorderWidth: 1
        },
        {
          data: [0, 0, 0.2, 0.6, 0.8, 1, 1.2, 1.4], 
          label: 'eKtV', 
          fill: false, 
          borderWidth: 3, 
          pointBorderWidth: 1
      }
      ];

    public totalData:Array<any> = [
      {
        data: [0, 100, 200, 300, 400], 
        label: 'Total removal', 
        fill: false, 
        borderWidth: 3, 
        pointBorderWidth: 1
      }
    ];
      
      public lineChartLabels:Array<any> = [0];
      public lineChartOptions:any = {
        responsive: true,
        legend: {
          position: 'bottom'
        }
      };
      public lineChartColors:Array<any> = [
        { // grey
          backgroundColor: 'rgba(148,159,177,0.2)',
          borderColor: 'rgba(148,159,177,1)',
          pointBackgroundColor: 'rgba(148,159,177,1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(148,159,177,0.8)'
        },
        { // dark grey
          backgroundColor: 'rgba(77,83,96,0.2)',
          borderColor: 'rgba(77,83,96,1)',
          pointBackgroundColor: 'rgba(77,83,96,1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(77,83,96,1)'
        },
        { // grey
          backgroundColor: 'rgba(148,159,177,0.2)',
          borderColor: 'rgba(148,159,177,1)',
          pointBackgroundColor: 'rgba(148,159,177,1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(148,159,177,0.8)'
        }
      ];
      public lineChartLegend:boolean = true;
      public lineChartType:string = 'line';
     
      public randomize():void {
        let _lineChartData:Array<any> = new Array(this.lineChartData.length);
        for (let i = 0; i < this.lineChartData.length; i++) {
          _lineChartData[i] = {data: new Array(this.lineChartData[i].data.length), label: this.lineChartData[i].label};
          for (let j = 0; j < this.lineChartData[i].data.length; j++) {
            _lineChartData[i].data[j] = Math.floor((Math.random() * 100) + 1);
          }
        }
        this.lineChartData = _lineChartData;
      }
     
      // events
      public chartClicked(e:any):void {
        console.log(e);
      }
     
      public chartHovered(e:any):void {
        console.log(e);
      }

      public openKtv():void {
        this.lineChartData = this.ktvData;
      }

      public openTotal():void {
        this.lineChartData = this.totalData;
      }

}