
import {first, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { UserService } from './user.service';
import { Observable } from 'rxjs';

@Injectable()
export class RoleGuard {

    constructor(private authService: AuthService, private router: Router, private userService: UserService) {

    }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean>|boolean {
        const user = this.authService.getUser();

        if (!user) {
            this.router.navigate(['login']);
            return false;
        }

        if (!this.authService.isAuthenticated() || user.role != route.data.role) {
            
            return this.authService.refreshToken().pipe(map(res => {
                if (res.authToken) {

                    this.authService.setToken(res.authToken, res.user);

                    if (res.user.role === route.data.role) {
                        return true;
                    }
                    return false;
                }
                this.router.navigate(['login']);
                return false;
            }),first(),);
        }
        return true;

        
    }
}