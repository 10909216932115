import { trigger, state, animate, transition, style } from '@angular/animations';

export const slideInOutVerticalAnimation = trigger('slideInOutVerticalAnimation', [

    state('*', style({
        position: 'absolute',
        width: '100%'
    })),

    transition(':enter', [

        style({
            transform: 'translate3d(0, -100%, 0)'
        }),

        animate('.14s cubic-bezier(.51,.35,.61,.99)', style({
            transform: 'translate3d(0, 0, 0)'
        }))

    ]),

    transition(':leave', [
        animate('.14s cubic-bezier(.51,.35,.61,.99)', style({
            transform: 'translate3d(0, -100%, 0)'
        }))
    ])

]);