import { Component, Input, OnInit } from '@angular/core';
import { LoadingService } from '../../services/loading.service';

@Component({
    selector: 'loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss']
})

export class LoadingComponent implements OnInit {

    public show = false;

    constructor(private loadingService: LoadingService) {}

    ngOnInit() {
        this.loadingService._register(this);
    }

}