import { NgModule } from '@angular/core';
import { RouterModule, Routes, CanActivate } from '@angular/router';

import { LoginView } from './views/LoginView/login-view.component';
import { RegisterView } from './views/RegisterView/register-view.component';
import { NotFoundView } from './views/NotFoundView/not-found.component';
import { PatientHomeView } from './views/PatientHomeView/patient-home-view.component';
import { StartSessionView } from './views/StartSessionView/start-session.component';
import { LiveView } from './views/LiveView/live-view.component';
import { MockView } from './views/MockView/mock-view.component';
import { AdminView } from './views/AdminView/admin-view.component';

import { AuthGuard } from './services/auth-guard.service';
import { RoleGuard } from './services/role-guard.service';

const appRoutes: Routes = [
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    { path: 'login', component: LoginView },
    { path: 'register', component: RegisterView },
    { path: 'home', component: PatientHomeView, canActivate: [AuthGuard] },
    { path: 'live', component: LiveView, canActivate: [AuthGuard] },
    { path: 'live/:id', component: LiveView, canActivate: [AuthGuard] },
    { path: 'startsession', component: StartSessionView, canActivate: [AuthGuard] },
    { path: 'mock', component: MockView, canActivate: [AuthGuard] },
    { path: 'admin', component: AdminView, canActivate: [RoleGuard], data: { role: 0 } },
    { path: '**', component: NotFoundView },
];

@NgModule({
    imports: [
        RouterModule.forRoot(
            appRoutes,
            { enableTracing: false }
        )
    ],
    exports: [
        RouterModule
    ]
})

export class AppRoutingModule {}