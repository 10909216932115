import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './helpers/auth.interceptor';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PatientView } from './views/PatientView/patient-view.component';
import { PatientHomeView } from './views/PatientHomeView/patient-home-view.component';
import { NurseView } from './views/NurseView/nurse-view.component';
import { LiveView } from './views/LiveView/live-view.component';
import { StartSessionView } from './views/StartSessionView/start-session.component';
import { MockView } from './views/MockView/mock-view.component';
import { RatingView } from './views/RatingView/rating-view.component';
import { MedicineView } from './views/MedicineView/medicine-view.component';
import { SessionView } from './views/SessionView/session-view.component';
import { LoginView } from './views/LoginView/login-view.component';
import { AdminView } from './views/AdminView/admin-view.component';
import { RegisterView } from './views/RegisterView/register-view.component';
import { NotFoundView } from './views/NotFoundView/not-found.component';
import { Navigation } from './components/Navigation/navigation.component';
import { LoadingComponent } from './components/LoadingComponent/loading.component';
import { LoadingService } from './services/loading.service';
import { NotificationComponent } from './components/NotificationComponent/notification.component';
import { NotificationService } from './services/notification.service';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './services/auth-guard.service';
import { RoleGuard } from './services/role-guard.service';
import { HttpService } from './services/http.service';
import { UserService } from './services/user.service';
import { ChartsModule } from 'ng2-charts';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { ChartModule } from 'angular-highcharts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AppComponent,
    PatientView,
    PatientHomeView,
    NurseView,
    LiveView,
    MockView,
    AdminView,
    RatingView,
    MedicineView,
    SessionView,
    StartSessionView,
    LoginView,
    RegisterView,
    NotFoundView,
    Navigation,
    LoadingComponent,
    NotificationComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    ChartsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ChartModule,
    BrowserAnimationsModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    LoadingService,
    AuthService,
    RoleGuard,
    AuthGuard,
    HttpService,
    UserService,
    NotificationService
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
