import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
    templateUrl: './medicine-view.component.html',
    styleUrls: ['./medicine-view.component.scss']
})

export class MedicineView implements OnInit {

    today: number = Date.now();
    
    constructor() {

    }
    
    ngOnInit() {

    }
}