
import {throwError as observableThrowError} from 'rxjs';

import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import { SensorData } from '../models/sensor-data.model';
import { SignUpUser } from '../models/signup.interface';
import { RegisterResponse } from '../models/responses.interface';
import { AuthService } from './auth.service';



import 'rxjs/Rx';



@Injectable()

export class HttpService {
    
    public apiUrl = 'http://api.optofluidtech.com:8080/';
    //public apiUrl = 'http://localhost:8080/';

    constructor(private http: HttpClient) {}

    startSession(patientId: string, weight: number, uf: number, duration: number, flow: number) {
        console.log('Starting session with patient id: ' + patientId);

        return this.http.post(this.apiUrl + 'session/start', { 'pid': patientId, 'time': new Date(), 'weight': weight, 'uf': uf, 'duration': duration, 'flow': flow }).pipe(
        catchError((error: any) => observableThrowError(error || 'Server error')));
    }

    register(model: SignUpUser) {
        return this.http.post<RegisterResponse>(this.apiUrl + 'auth/register', {firstName: model.firstName, lastName: model.lastName, email: model.email, password: model.passwords.password});
    }

    getData(id: string, type: string): Observable<SensorData> {
        return this.http.get<SensorData>(this.apiUrl + 'xls/data?id='+id+'&type='+type).pipe(catchError((error: any) => observableThrowError(error || 'Server error')));
    }

    getPoint(id: string, time?: string) {

        let body = {
            'id': id
        };

        if (time) {
            body['time'] = time;
        }

        return this.http.post(this.apiUrl + 'session/point', body).pipe(catchError((error: any) => observableThrowError(error || 'Server error')));
    }

    getSessions() {
        return this.http.get(this.apiUrl + 'session/list').pipe(catchError((error: any) => observableThrowError(error || 'Server error')));
    }

    startEmulation(id, count) {
        if (!count) return;
        return this.http.post(this.apiUrl + 'session/datafromfile', {id: id, lines: count}).pipe(catchError((error: any) => observableThrowError(error || 'Server error')));
    }
}