import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../services/http.service';
import { Router } from '@angular/router';
import { NotificationService } from '../../services/notification.service';
import { LoadingService } from '../../services/loading.service';
import { Notification } from 'rxjs';
import { slideInOutHorizontalAnimation } from '../../animations/slideInOutHorizontal.animation';

@Component({
    animations: [ slideInOutHorizontalAnimation ],
    host: { '[@slideInOutHorizontalAnimation]': '' },
    templateUrl: './start-session.component.html',
    styleUrls: ['./start-session.component.scss']
})

export class StartSessionView implements OnInit {

    patientId: string;
    weight: number;
    uf: number;
    flow: number;
    duration: number;
    liveSessions: string[];

    constructor(private httpService: HttpService, private router: Router, private notificationService: NotificationService, private loadingService: LoadingService) {

        this.httpService = httpService;
        this.router = router;
    }
    
    ngOnInit() {
        
        this.httpService.getSessions().subscribe((data: any) => {
            const sessions = JSON.parse(data);
            this.liveSessions = sessions;
        }, err => { console.log(err); });
    }

    start() {

        this.loadingService.show();

        this.httpService.startSession(this.patientId, this.weight, this.uf, this.duration, this.flow).subscribe((data: any) => {
            
            if (data.sessionId.body) {
                this.httpService.startEmulation(data.sessionId.body, 20).subscribe(res => { console.log(data); }, err => { console.log(err); });
                sessionStorage.setItem('currentSession', data.sessionId.body);
                this.loadingService.hide();
                this.router.navigate(['/live', data.sessionId.body]);
            }

        }, (err: any) => {
            
            if (err.status == 400) {
                console.log('Missing parameters');
                this.notificationService.error('Mõned väljad on täitmata. Palun täida kõik väljad.');
            }

            this.loadingService.hide();

        });
    }

    openSession(id) {
        this.router.navigate(['/live', id]);
    }

    modelChanged(element) {
        
        if (element.value != '') {
            element.classList.add('has-value');
            return;
        }
        element.classList.remove('has-value');
    }

}