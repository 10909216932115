import { trigger, state, animate, transition, style } from '@angular/animations';

export const slideInOutHorizontalAnimation = trigger('slideInOutHorizontalAnimation', [

    state('*', style({
        position: 'absolute',
        width: '100%',
        top: '100px',
        backgroundColor: 'rgba(255,255,255,1)'
    })),

    transition(':enter', [

        style({
            transform: 'translate3d(-100%, 0, 0)'
        }),

        animate('.24s cubic-bezier(.51,.35,.61,.99)', style({
            transform: 'translate3d(0, 0, 0)'
        }))

    ]),

    transition(':leave', [

        style({
            position: 'absolute',
            top: '100px'
        }),

        animate('.24s cubic-bezier(.51,.35,.61,.99)', style({
            transform: 'translate3d(100%, 0, 0)'
        }))
    ])

]);