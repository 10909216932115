
import {takeWhile} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../services/http.service';
import { Chart } from 'angular-highcharts';
import { ActivatedRoute } from '@angular/router';
import { IntervalObservable } from 'rxjs/observable/IntervalObservable';
import { OnDestroy } from '@angular/core/src/metadata/lifecycle_hooks';
import { NotificationService } from '../../services/notification.service';
import { LoadingService } from '../../services/loading.service';
import { slideInOutHorizontalAnimation } from '../../animations/slideInOutHorizontal.animation';

@Component({
  animations: [ slideInOutHorizontalAnimation ],
  host: { '[@slideInOutHorizontalAnimation]': '' },
  templateUrl: './live-view.component.html',
  styleUrls: ['./live-view.component.scss']
})

export class LiveView implements OnInit, OnDestroy {

  id: string;
  // is session active
  alive: boolean;
  // time of latest data point
  time: string;
  // data request interval
  interval: number = 10000;

  constructor(private httpService: HttpService, private route: ActivatedRoute, private notificationService: NotificationService, private loadingService: LoadingService) {
    this.route = route;
  }
  
  ngOnInit() {

    this.alive = false;

    this.route.params.subscribe(params => {

      if (!params.id && !sessionStorage.getItem('currentSession')) {
        console.log('missing session id');
        this.notificationService.error('Puudub sessiooni ID.');
        return;
      }

      this.id = params.id ? params.id : sessionStorage.getItem('currentSession');

      this.httpService.getPoint(this.id).subscribe((data: any) => {

        console.log(data);

        if (!data.values) {
          console.log('did not get any values');
          this.notificationService.error('Andmete laadimine ebaõnnestus.');
          return;
        }

        this.time = data.time;
        this.alive = true;

        this.chart.addSerie({
          id: 'ktv',
          name: 'KtV',
          data: data['values']
        });

      }, err => {
        console.log(err);
        this.notificationService.error('Ei leia sessiooni mille ID on ' + this.id);
      });

      IntervalObservable.create(this.interval).pipe(takeWhile(() => this.alive)).subscribe(() => {

        this.httpService.getPoint(this.id, this.time).subscribe((data: any) => {
          
          if (data.value) {
            this.chart.addPoint(data.value);
            return;
          }

          if (data.end == true) {
            this.notificationService.success('Dialüüs on lõppenud.');
            this.stop();
          }

        }, err => {
          console.log(err);
          this.notificationService.error('Uue andmepunkti laadimine ebaõnnestus.');
        });

      }); 

    });

  }

  chart = new Chart({
    chart: {
      type: 'area'
    },
    colors: ['#cddc39', '#e91e63', '#03a9f4', '#ffdc3e', '#8bc34a', '#ff5722'],
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    plotOptions : {
      area : {
        lineWidth : 2,
        fillOpacity: 0.7,
        marker : {
          enabled : false,
          states : {
            hover : {
              enabled : true,
              radius : 5
            }
          }
        },
        shadow : false,
        states : {
          hover : {
            lineWidth : 1
          }
        }
      }
    }
  });

  ngOnDestroy(): void {
    this.alive = false;
  }

  stop() {
    this.alive = false;
    sessionStorage.removeItem('currentSession');
  }

  handleType(event) {
    
    if (event.target.checked) {

      this.httpService.getData('test', event.target.name).subscribe(data => {

        this.chart.addSerie({
          id: event.target.name,
          name: event.target.name,
          data: data['values']
        });

        console.log(data);

      }, err => {

        console.log(err);

      });

      return;
    }
    this.chart.removeSerie(this.chart.ref.series.findIndex(i => i.name == event.target.name));

  }

}