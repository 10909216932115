import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../services/http.service';
import { AuthService } from '../../services/auth.service';
import { LoadingService } from '../../services/loading.service';
import { Router } from '@angular/router';
import { NotificationService } from '../../services/notification.service';

@Component({
    templateUrl: './login-view.component.html',
    styleUrls: ['./login-view.component.scss'],
    providers: [HttpService]
})

export class LoginView implements OnInit {

    model: any = {};
    status: number;

    constructor(private httpService: HttpService, private authService: AuthService, private loadingService: LoadingService, private router: Router, private notificationService: NotificationService) {
    }
    
    ngOnInit() {
        if (this.authService.isAuthenticated()) {
            this.router.navigate(['home']);
        }
    }

    login(): void {

        this.authService.login(this.model.email, this.model.password);

    }

}