import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { User } from '../../models/user.interface';
import { AuthService } from '../../services/auth.service';
import { fadeInOutAnimation } from '../../animations/fadeInOut.animation';

@Component({
    animations: [ fadeInOutAnimation ],
    templateUrl: './admin-view.component.html',
    styleUrls: ['./admin-view.component.scss']
})

export class AdminView implements OnInit {

    constructor(private userService: UserService, private authService: AuthService) {

    }

    users: User[];
    currentUser: User;
    roles = [
        {
            id: 0,
            label: 'Admin'
        },
        {
            id: 1,
            label: 'Doctor'
        },
        {
            id: 2,
            label: 'Nurse'
        },
        {
            id: 3,
            label: 'Patient'
        }
    ];

    ngOnInit() {
        
        this.userService.getUsers().subscribe(res => {
            this.users = res;
        }, err => {
            console.log('Error getting users');
        });

        this.currentUser = this.authService.getUser();
    }

    roleChanged(event, userId) {
        const role = event.target.value;
        
        this.userService.setRole(userId, role).subscribe(res => {
            console.log(res);
        }, err => {
            console.log(err);
        });
    
    }

    revokeToken(userId) {
        this.userService.revokeToken(userId).subscribe(res => {
            console.log(res);
        }, err => {
            console.log(err);
        });
    }

}