
import {map} from 'rxjs/operators';

import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { HttpService } from './http.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { LoadingService } from './loading.service';
import { NotificationService } from './notification.service';
import { LoginResponse, RefreshTokenResponse } from '../models/responses.interface';

import { User } from '../models/user.interface';

@Injectable()
export class AuthService {

    loggedIn: EventEmitter<any>;

    helper = new JwtHelperService();

    public getToken(): string {
        return localStorage.getItem('authToken');
    }
    
    public getUser(): User {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        
        let user: User = {
            id: storedUser.id,
            firstName: storedUser.firstName,
            role: storedUser.role
        };

        return user;
    }

    public getRefreshToken(): string {
        return localStorage.getItem('refreshToken');
    }

    public setToken(token, user): void {
        localStorage.setItem('authToken', token);
        if (!user) return;
        localStorage.setItem('user', JSON.stringify(user));
    }

    constructor(private http: HttpClient, private httpService: HttpService, private loadingService: LoadingService, private router: Router, private notificationService: NotificationService) {
        this.loggedIn = new EventEmitter();
    }

    isAuthenticated(): boolean {

        const token = this.getToken();

        if (token && !this.helper.isTokenExpired(token)) {
            return true;
        }
        return false;
        
    }

    login(email: string, password: string) {
        this.loadingService.show();
        return this.http.post<LoginResponse>(this.httpService.apiUrl + 'auth/login', {email: email, password: password}).subscribe(res => {
 
            if (!res || !res.authToken || !res.refreshToken) {
                this.loadingService.hide();
                return;
            }

            this.setToken(res.authToken, res.user);
            localStorage.setItem('refreshToken', res.refreshToken);

            this.loadingService.hide();
            this.loggedIn.emit(true);
            this.router.navigate(['home']);
        }, err => {

            this.loadingService.hide();
            this.notificationService.error('Sisselogimine ebaõnnestus. Vale e-posti aadress või salasõna.');
            return observableThrowError(err);
        });
    }

    logout(): void {
        localStorage.removeItem('authToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('user');
    }

    refreshToken(): Observable<RefreshTokenResponse> {
        return this.http.post<RefreshTokenResponse>(this.httpService.apiUrl + 'auth/refreshToken', {refreshToken: this.getRefreshToken()}).pipe(map(res => {
            if (res.authToken) {
                this.loggedIn.emit(true);
            }
            return res;
        }));
    }
}