import { trigger, state, animate, transition, style } from '@angular/animations';

export const fadeInOutAnimation = trigger('fadeInOutAnimation', [

    state('*', style({
    })),

    transition(':enter', [

        style({
            opacity: '0'
        }),

        animate('.4s cubic-bezier(.51,.35,.61,.99)', style({
            opacity: '1'
        }))

    ]),

    transition(':leave', [

        style({
            opacity: '0'
        }),

        animate('.1s cubic-bezier(.51,.35,.61,.99)', style({
            opacity: '0'
        }))
    ])

]);