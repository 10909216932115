import { Injectable } from '@angular/core';
import { LoadingComponent } from '../components/LoadingComponent/loading.component';

@Injectable()
export class LoadingService {

    private loader: LoadingComponent;

    _register(loader: LoadingComponent): void {
        this.loader = loader;
    } 

    public show(): void {
        this.loader.show = true;
    }

    public hide(): void {
        this.loader.show = false;
    }

}