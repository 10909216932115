import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import { HttpService } from './http.service';
import { User } from '../models/user.interface';

@Injectable()
export class UserService {

    constructor(private http: HttpClient, private httpService: HttpService) {}

    getUser(): Observable<User> {
        return this.http.get<User>(this.httpService.apiUrl + 'auth/me');
    }

    getUsers(): Observable<User[]> {
        return this.http.get<User[]>(this.httpService.apiUrl + 'users/all');
    }

    setRole(id, role) {
        return this.http.put(this.httpService.apiUrl + 'users/setrole', {id: id, value: role});
    }

    revokeToken(id) {
        return this.http.put(this.httpService.apiUrl + 'users/revokeToken', {id: id});
    }

}