import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../services/http.service';
import { LoadingService } from '../../services/loading.service';
import { AuthService } from '../../services/auth.service';
import { NotificationService } from '../../services/notification.service';
import { SignUpUser } from '../../models/signup.interface';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';

@Component({
    templateUrl: './register-view.component.html',
    styleUrls: ['./register-view.component.scss'],
    providers: [HttpService],
})

export class RegisterView implements OnInit {
    registerForm: FormGroup;

    loading: boolean;
    success: boolean;

    constructor(
        private httpService: HttpService, 
        private loadingService: LoadingService, 
        private formBuilder: FormBuilder, 
        private notificationService: NotificationService,
        private authService: AuthService
    ) {
        this.createForm();
    }
    
    ngOnInit() {
    }

    createForm() {
        this.registerForm = this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            email: ['', [Validators.email, Validators.required]],
            passwords: this.formBuilder.group({
                password: ['', [Validators.required]],
                confirmPassword: ['', [Validators.required]]
            }, { validator: this.passwordMatch })
        });
    }

    register() {
        this.loadingService.show();

        let model: SignUpUser = this.registerForm.value;

        this.httpService.register(model).subscribe(res => {
            console.log('Got response');
            if (res) {
                console.log(res);
                this.success = true;
                setTimeout(() => {
                    this.authService.login(model.email, model.passwords.password);
                }, 2000);
            }
            else {
                console.log('Error with server');
            }
        }, err => {
            console.log(err);
            if (err.status == 409) {
                this.notificationService.error('Selline kasutaja on juba olemas');
            }
        });

        this.loadingService.hide();
    }

    passwordMatch(passwords: AbstractControl): { invalid: boolean } {
        if (passwords.get('password').value != passwords.get('confirmPassword').value) {
            console.log('Form invalid');
            return { invalid: true };
        }
    }

}